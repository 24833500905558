import {uploadLoginData, uploadPayData} from "@/utils/baiduOCPC";
import authAxios from "@/axios/authAxios";

export function trackLogin() {
  try {
    if (process.env.VUE_APP_REPORT_BAI_DU === 'true') {
      uploadLoginData().catch(e => {})
    }
    if (process.env.VUE_APP_REPORT_BING === 'true') {
      window.uetq = window.uetq || []
      window.uetq.push('event', 'login', {'event_category': 'login'})
    }
    if (process.env.VUE_APP_REPORT_TENCENT === 'true') {

    }
  } catch (e) {
  }

}

export function trackOrder(id, orderId, price) {
  try {
    if (process.env.VUE_APP_REPORT_BAI_DU === "true") {
      uploadPayData(price).catch(e => {})
    }
    if (process.env.VUE_APP_REPORT_BING === "true") {
      window.uetq = window.uetq || []
      window.uetq.push('event', 'purchase', {
        'event_category': 'purchase',
        'event_label': `${orderId}${id}`,
        'event_value': price
      })
    }
    if (process.env.VUE_APP_REPORT_TENCENT === "true") {
      gdt('track', 'COMPLETE_ORDER', {
        order_id: orderId,
        price: price
      })
    }

  } catch (e) {
  }
}

/**
 * 功能统计访问
 * @param functionName 功能标识
 * @param title 功能描述
 */
export function trackUsageFunc (functionName, title) {
  authAxios.post('/imageformatV2/sentuser', {
    use_func: 'image_'+functionName,
    use_func_title: '图片压缩_'+title
  }).catch(e => {})
}