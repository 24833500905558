import authAxios from "@/axios/authAxios";

/**
 * 绑定微信
 * @param union_str 微信uuid
 * @returns {Promise<AxiosResponse<any>>}
 */
export const bindWechat = union_str => {
  return authAxios.post('/imageformatV2/bindwechat', {
    union_str: union_str
  })
}
/**
 * 导入订单号数据
 * @param order_id 订单ID
 * @returns {Promise<AxiosResponse<any>>}
 */
export const bindOrder = (order_id) => {
  return authAxios.post('/imageformatV2/transactionidlogin', {
    transaction_id: order_id
  })
}