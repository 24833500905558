<template>
  <div class="tabBar">
    <div :class="{tabItem: true, active: $route.path === tab.path}" v-for="(tab,idx) in tabBar" :key="idx" @click="jumpTo(tab.path)">
      <span class="icon" :style="$route.path === tab.path ? {backgroundImage: `url(${tab.activeIcon})`} : {backgroundImage: `url(${tab.icon})`}"></span>
      <span class="txt">{{tab.title}}</span>
    </div>
    <div v-if="visible" @click="handleDownloadApp" class="openAppBtn">
      <img src="@/assets/img/app_icon.svg" alt="">
      打开APP
    </div>
    <DownloadMask :visible="show" @onClose="handleHidden" />
  </div>
</template>
<script>
import {getMobileOS, isMobile, isWeiXin} from "@/utils/getDevice";
import {appConfig} from "@/config";
import DownloadMask from "@/components/DownloadMask/index.vue";

export default {
  name: 'MyTabBar',
  components: {DownloadMask},
  props: {},
  data() {
    return {
      tabBar: [
        {
          title: '轻秒',
          path: '/',
          icon: require('@/assets/img/nav_icon_qingmiao.svg'),
          activeIcon: require('@/assets/img/nav_icon_qingmiao_active.svg')
        },
        {
          title: '我的',
          path: '/personal-center',
          icon: require('@/assets/img/nav_icon_me.svg'),
          activeIcon: require('@/assets/img/nav_icon_me_active.svg')
        }
      ],
      show: false
    }
  },
  computed: {
    visible() {
      return isMobile(navigator.userAgent) && appConfig.allowDownloadApp
    }
  },
  methods: {
    jumpTo(path) {
      if (this.$route.path !== path) {
        this.$router.push(path).catch(e => {})
      }
    },
    handleHidden() {
      this.show = false
    },
    handleDownloadApp() {
      if (isWeiXin(window.navigator.userAgent) && isMobile(navigator.userAgent)) {
        this.show = true
      } else {
        let downloadLink = 'https://res.yunkun.cn/softdownload/qmimgapp.apk'
        if (getMobileOS(window.navigator.userAgent) === 'ios') {
          downloadLink =
                  'https://apps.apple.com/cn/app/%E8%BD%BB%E7%A7%92%E5%9B%BE%E7%89%87%E8%BD%AC%E6%8D%A2%E5%99%A8/id6739018586'
        }
        const a = document.createElement('a')
        a.href = downloadLink
        a.click()
      }
    }
  },
  created() {}
}
</script>
<style scoped lang="less">
@import url('./index.less');
</style>
