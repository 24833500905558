import authAxios from '@/axios/authAxios'
import axios from "axios";

export function uploadFile() {}

export function getOSSToken() {
  return authAxios.post('/imageformat/getosstoken')
}
export function getTempOSSToken() {
  return axios.post('/api/getosstoken')
}
export function checkUserImage(token) {
  return authAxios.post('/imageformatV2/checkuserimage', {
    dl_token: token
  })
}