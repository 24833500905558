/**
 * VIP 等级定义
 */
export const VIP_LEVEL = {
  NON_VIP: 0, // 非vip
  COUNT_VIP: 5, // 次数vip
  TIME_VIP: 10, // 时间vip
  PERMANENT_VIP: 100, // 永久会员
  THREE_DAY_VIP: 7, // 3天会员
  YEAR_VIP: 10, // 年会员
}
/**
 * 购买页套餐配置
 * */
export const packageList = [
  {
    id: 1,
    title: '终身会员',
    price: '69',
    corner: '直降500元',
    desc: '¥569',
    txt: '终身免费使用',
    has_icon: true, // 是否有闪电符号
    has_del: true, // 是否有删除线
    unit: '终身',
    payTips: '直降500元 可开发票',
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.COUNT_VIP, VIP_LEVEL.PERMANENT_VIP]
  },
  {
    id: 10,
    title: '终身会员',
    price: '39',
    corner: '限时升级终身',
    desc: '¥569',
    txt: '终身免费使用',
    has_icon: true, // 是否有闪电符号
    has_del: true, // 是否有删除线
    unit: '终身',
    payTips: '直降530元 可开发票',
    level: [ VIP_LEVEL.YEAR_VIP, VIP_LEVEL.THREE_DAY_VIP, VIP_LEVEL.COUNT_VIP]
  },
  {
    id: 2,
    title: '年会员',
    price: '49',
    corner: '立减130',
    desc: '¥179',
    txt: '低至0.13元/天',
    has_icon: false,
    has_del: true,
    unit: '一年',
    payTips: '立减130元 可开发票',
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.COUNT_VIP, VIP_LEVEL.PERMANENT_VIP]
  },
  {
    id: 11,
    title: '3天体验VIP',
    price: '19.9',
    corner: '',
    desc: '≈1杯奶茶',
    txt: '不限次数使用',
    has_icon: false,
    has_del: false,
    unit: '3天VIP',
    payTips: '不限次数使用',
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.COUNT_VIP, VIP_LEVEL.PERMANENT_VIP]
  },
  // {
  //   id: 47,
  //   title: '50张(永久)',
  //   price: '29',
  //   corner: '',
  //   desc: '≈1张电影票',
  //   txt: '张数永久有效',
  //   has_icon: false,
  //   has_del: false,
  //   unit: '50张(永久)',
  //   payTips: '张数永久有效',
  //   level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.COUNT_VIP, VIP_LEVEL.YEAR_VIP, VIP_LEVEL.THREE_DAY_VIP, VIP_LEVEL.PERMANENT_VIP]
  // },
  {
    id: 4,
    title: '按张付费*10张',
    price: '19.9',
    corner: '',
    desc: '1.99元/张',
    txt: '张数永久有效',
    has_icon: false,
    has_del: false,
    unit: '10张',
    payTips: '张数永久有效',
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.COUNT_VIP, VIP_LEVEL.YEAR_VIP, VIP_LEVEL.THREE_DAY_VIP, VIP_LEVEL.PERMANENT_VIP]
  },
  // {
  //   id: 3,
  //   title: '按张付费*1张',
  //   price: '9.9',
  //   corner: '',
  //   desc: '9.9元/张',
  //   txt: '张数永久有效',
  //   has_icon: false,
  //   has_del: false,
  //   unit: '张',
  //   payTips: '张数永久有效',
  //   level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.COUNT_VIP, VIP_LEVEL.YEAR_VIP, VIP_LEVEL.THREE_DAY_VIP, VIP_LEVEL.PERMANENT_VIP]
  // }
  {
    id: 71,
    title: '按张付费*1张',
    price: '12.9',
    corner: '',
    desc: '12.9元/张',
    txt: '张数永久有效',
    has_icon: false,
    has_del: false,
    unit: '张',
    payTips: '张数永久有效',
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.COUNT_VIP, VIP_LEVEL.YEAR_VIP, VIP_LEVEL.THREE_DAY_VIP, VIP_LEVEL.PERMANENT_VIP]
  }
  // {
  //   id: 89,
  //   title: '按张付费*1张',
  //   price: '9.9',
  //   corner: '',
  //   desc: '可使用2张',
  //   txt: '买1张送1张',
  //   has_icon: false,
  //   has_del: false,
  //   unit: '张',
  //   payTips: '张数永久有效',
  //   level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.COUNT_VIP, VIP_LEVEL.YEAR_VIP, VIP_LEVEL.THREE_DAY_VIP, VIP_LEVEL.PERMANENT_VIP]
  // }
]
/**
 * 购买页搜狗套餐配置
 * */
export const sougouPackageList = []