<template>
  <div class="homePage">
    <div class="funcArea">
      <div class="funcListBox">
        <div class="funcList" ref="funcListBox">
          <div v-for="item in config" :key="item.function" :class="{ funcItem: true, checked: selectFunc === item.function }" @click="onSelectFunc(item.function)">
            <span :class="{'icon': true, [item.iconClass]: true}"></span>
            <span class="title">{{item.title}}</span>
          </div>
<!--          <div :class="{ funcItem: true, checked: selectFunc === 'edit' }" @click="onSelectFunc('edit')">-->
<!--            <span class="icon size_icon"></span>-->
<!--            <span class="title">修改尺寸</span>-->
<!--          </div>-->
<!--          <div :class="{ funcItem: true, checked: selectFunc === 'compress' }" @click="onSelectFunc('compress')">-->
<!--            <span class="icon compress_icon"></span>-->
<!--            <span class="title">图片压缩</span>-->
<!--          </div>-->
<!--          <div-->
<!--                  :class="{ funcItem: true, checked: selectFunc === 'convert' }"-->
<!--                  @click="onSelectFunc('convert')"-->
<!--          >-->
<!--            <span class="icon format_icon"></span>-->
<!--            <span class="title">格式转换</span>-->
<!--          </div>-->
<!--          <div :class="{ funcItem: true, checked: selectFunc === 'edit-dpi' }" @click="onSelectFunc('edit-dpi')">-->
<!--            <span class="icon dpi_icon"></span>-->
<!--            <span class="title">修改DPI</span>-->
<!--          </div>-->

<!--          <div :class="{ funcItem: true, checked: selectFunc === 'photoZoomPro' }" @click="onSelectFunc('photoZoomPro')">-->
<!--            <span class="icon photo_zoom_icon"></span>-->
<!--            <span class="title">无损放大</span>-->
<!--          </div>-->
<!--          <div-->
<!--                  :class="{ funcItem: true, checked: selectFunc === 'pictureClear' }"-->
<!--                  @click="onSelectFunc('pictureClear')"-->
<!--          >-->
<!--            <span class="icon clean_icon"></span>-->
<!--            <span class="title">图片变清晰</span>-->
<!--          </div>-->
<!--          <div :class="{ funcItem: true, checked: selectFunc === 'repairingPhotos' }" @click="onSelectFunc('repairingPhotos')">-->
<!--            <span class="icon repairing_photo_icon"></span>-->
<!--            <span class="title">老照片修复</span>-->
<!--          </div>-->

<!--          <div :class="{ funcItem: true, checked: selectFunc === 'coloringPhotos' }" @click="onSelectFunc('coloringPhotos')">-->
<!--            <span class="icon up_color_icon"></span>-->
<!--            <span class="title">黑白照上色</span>-->
<!--          </div>-->
        </div>
      </div>
      <Uploader v-if="isAllowMultiple" :on-success="onUploadSuccess" class="uploader" :multiple="isAllowMultiple" :accept="accept">
        <div class="uploadContainer">
          <span class="icon"></span>
          <div class="tips">{{ uploadTips }}</div>
        </div>
      </Uploader>
      <Uploader v-else :on-success="onUploadSuccess" class="uploader" :multiple="isAllowMultiple" :accept="accept" :max-count="1">
        <div class="uploadContainer">
          <span class="icon"></span>
          <div class="tips">{{ uploadTips }}</div>
        </div>
      </Uploader>
    </div>
    <div class="featureArea">
      <div class="areaTitle">我们的优势</div>
      <div class="featureList">
        <div class="featureItem">
          <div class="featureItemTop">
            <span class="icon icon1"></span>
            <span class="title">实时处理</span>
          </div>
          <div class="featureItemBottom">在线实时处理，无需等待或排队，方便快捷；</div>
        </div>
        <div class="featureItem">
          <div class="featureItemTop">
            <span class="icon icon2"></span>
            <span class="title">隐私安全</span>
          </div>
          <div class="featureItemBottom">处理完成60分钟后，将会永久删除，100%保障你的隐私；</div>
        </div>
        <div class="featureItem">
          <div class="featureItemTop">
            <span class="icon icon3"></span>
            <span class="title">功能丰富</span>
          </div>
          <div class="featureItemBottom">web端提供批量图片压缩、格式转换、PDF转换等功能；</div>
        </div>
        <div class="featureItem">
          <div class="featureItemTop">
            <span class="icon icon4"></span>
            <span class="title">多端通用</span>
          </div>
          <div class="featureItemBottom">无需安装，移动端与网页权益互通，PC端支持更多功能；</div>
        </div>
        <div class="featureItem" v-if="appConfig.companyInfo?.businessLicense">
          <div class="featureItemTop">
            <span class="icon icon5"></span>
            <span class="title">资质实力</span>
          </div>
          <div class="featureItemBottom">
            <p>网址：{{appConfig.website}} &nbsp;&nbsp;&nbsp; 软著登记号：{{appConfig.companyInfo?.softRegistrationNumber}}</p>
            <p><a href="https://res.yunkun.cn/pdf_geshicn/ykyyzz.png" target="_blank" class="link">查看营业执照</a></p>
          </div>
        </div>
      </div>
    </div>
    <MyTabBar />
  </div>
</template>
<script>
import MyTabBar from '@/components/TabBar/index.vue'
import Uploader from '@/components/Uploader/index.vue'
import {appConfig} from '@/config'

export default {
  name: 'Home',
  components: { Uploader, MyTabBar },
  props: {},
  data() {
    return {
      config: [
        {
          function: 'edit',
          iconClass: 'size_icon',
          title: '修改尺寸',
          uploadConfig: {
            tips: '同时处理证件照尺寸、格式、指定大小',
            accept: 'image/*'
          }
        },
        {
          function: 'compress',
          iconClass: 'compress_icon',
          title: '图片压缩',
          uploadConfig: {
            tips: '证件照压缩、保持清晰度、压缩指定大小',
            accept: 'image/*'
          }
        },
        {
          function: 'convert',
          iconClass: 'format_icon',
          title:'格式转换',
          uploadConfig: {
            tips: '批量转换图片格式为JPG、PNG、WEBP等',
            accept: 'image/*'
          }
        },

        {
          function: 'edit-dpi',
          iconClass: 'dpi_icon',
          title: '修改DPI',
          uploadConfig: {
            tips: '批量处理图片分辨率DPI',
            accept: 'image/*'
          }
        },
        {
          function: 'photoZoomPro',
          iconClass: 'photo_zoom_icon',
          title: '无损放大',
          uploadConfig: {
            tips: '一键无损放大图片，支持放大2-8倍',
            accept: 'image/*'
          }
        },
        {
          function: 'pictureClear',
          iconClass: 'clean_icon',
          title: '图片变清晰',
          uploadConfig: {
            tips: 'AI智能修复模糊图片，使模糊图片变清晰',
            accept: 'image/*'
          }
        },
        {
          function: 'repairingPhotos',
          iconClass: 'repairing_photo_icon',
          title: '老照片修复',
          uploadConfig: {
            tips: 'AI智能修复模糊老照片',
            accept: 'image/*'
          }
        },

        {
          function: 'coloringPhotos',
          iconClass: 'up_color_icon',
          title: '黑白照上色',
          uploadConfig: {
            tips: '黑白照片上色，还原场景色彩',
            accept: 'image/*'
          }
        }
      ],
      appConfig: appConfig,
      isAllowMultiple: true, //是否允许多张上传
      selectFunc: '', // 当前选中的方法
      uploadTips: '',
      accept: 'image/*'
    }
  },
  methods: {
    onUploadSuccess(params) {
      this.$router.push({
        name: this.selectFunc,
        params: {
          fileList: [...params]
        }
      })
    },
    onSelectFunc(func) {
      if (func === 'edit') {
        this.$router.replace('/').catch(() => {})
      } else {
        this.$router.replace('/?func=' + func).catch(() => {})
      }



    },
    handleSelect(func) {
      let index = this.config.findIndex(item => item.function === func)
      let temp = this.config[index]
      this.config.splice(index, 1)
      this.config =[temp,...this.config]

      this.selectFunc = func
      this.isAllowMultiple = !(['edit','photoZoomPro', 'repairingPhotos', 'pictureClear', 'coloringPhotos'].includes(this.selectFunc))

      if (this.$refs?.funcListBox) {
        // console.log(this.$refs.funcListBox.scrollLeft)
      this.$refs.funcListBox.scrollLeft = 0
      }


      let {
        uploadConfig: { tips,accept }
      } = this.config.find(item => item.function === func)
      this.uploadTips = tips
      this.accept = accept
    }
  },
  created() {
    this.handleSelect(this.$route.query?.func || 'edit')
  },
  beforeRouteUpdate(to, from,next) {
    this.handleSelect(to.query?.func || 'edit')
    next()
  }
}
</script>
<style scoped lang="less">
@import url('./index.less');
</style>
