var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homePage"},[_c('div',{staticClass:"funcArea"},[_c('div',{staticClass:"funcListBox"},[_c('div',{ref:"funcListBox",staticClass:"funcList"},_vm._l((_vm.config),function(item){return _c('div',{key:item.function,class:{ funcItem: true, checked: _vm.selectFunc === item.function },on:{"click":function($event){return _vm.onSelectFunc(item.function)}}},[_c('span',{class:{'icon': true, [item.iconClass]: true}}),_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.title))])])}),0)]),(_vm.isAllowMultiple)?_c('Uploader',{staticClass:"uploader",attrs:{"on-success":_vm.onUploadSuccess,"multiple":_vm.isAllowMultiple,"accept":_vm.accept}},[_c('div',{staticClass:"uploadContainer"},[_c('span',{staticClass:"icon"}),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.uploadTips))])])]):_c('Uploader',{staticClass:"uploader",attrs:{"on-success":_vm.onUploadSuccess,"multiple":_vm.isAllowMultiple,"accept":_vm.accept,"max-count":1}},[_c('div',{staticClass:"uploadContainer"},[_c('span',{staticClass:"icon"}),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.uploadTips))])])])],1),_c('div',{staticClass:"featureArea"},[_c('div',{staticClass:"areaTitle"},[_vm._v("我们的优势")]),_c('div',{staticClass:"featureList"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),(_vm.appConfig.companyInfo?.businessLicense)?_c('div',{staticClass:"featureItem"},[_vm._m(4),_c('div',{staticClass:"featureItemBottom"},[_c('p',[_vm._v("网址："+_vm._s(_vm.appConfig.website)+"     软著登记号："+_vm._s(_vm.appConfig.companyInfo?.softRegistrationNumber))]),_vm._m(5)])]):_vm._e()])]),_c('MyTabBar')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"featureItem"},[_c('div',{staticClass:"featureItemTop"},[_c('span',{staticClass:"icon icon1"}),_c('span',{staticClass:"title"},[_vm._v("实时处理")])]),_c('div',{staticClass:"featureItemBottom"},[_vm._v("在线实时处理，无需等待或排队，方便快捷；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"featureItem"},[_c('div',{staticClass:"featureItemTop"},[_c('span',{staticClass:"icon icon2"}),_c('span',{staticClass:"title"},[_vm._v("隐私安全")])]),_c('div',{staticClass:"featureItemBottom"},[_vm._v("处理完成60分钟后，将会永久删除，100%保障你的隐私；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"featureItem"},[_c('div',{staticClass:"featureItemTop"},[_c('span',{staticClass:"icon icon3"}),_c('span',{staticClass:"title"},[_vm._v("功能丰富")])]),_c('div',{staticClass:"featureItemBottom"},[_vm._v("web端提供批量图片压缩、格式转换、PDF转换等功能；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"featureItem"},[_c('div',{staticClass:"featureItemTop"},[_c('span',{staticClass:"icon icon4"}),_c('span',{staticClass:"title"},[_vm._v("多端通用")])]),_c('div',{staticClass:"featureItemBottom"},[_vm._v("无需安装，移动端与网页权益互通，PC端支持更多功能；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"featureItemTop"},[_c('span',{staticClass:"icon icon5"}),_c('span',{staticClass:"title"},[_vm._v("资质实力")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticClass:"link",attrs:{"href":"https://res.yunkun.cn/pdf_geshicn/ykyyzz.png","target":"_blank"}},[_vm._v("查看营业执照")])])
}]

export { render, staticRenderFns }