/**
 * 软件CODE
 */
export const SOFT_CODE = 'qingmiao'

export const SOFT_CHANNEL = ''

export const SUB_CODE = 'default'

export const BUCKET = 'pdfgeshi'

export const appConfig = {
  website: 'img.geshi.cn',
  companyInfo: {
    businessLicense: 'https://res.yunkun.cn/pdf_geshicn/ykyyzz.png',
    softRegistrationNumber: "2023SR1075944"
  },
  pay: {
    wechatPay: {
      returnUrl: 'https://img.geshi.cn'
    },
    aliPay: {
      returnUrl: 'https://img.geshi.cn'
    }
  },
  allowDownloadApp: true
}