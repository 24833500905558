<template>
  <div class="main" v-if="visible" @click="handleClose">
    <div class="promptBox">
      <div class="content">
        <div>点击右上角 ··· 选择浏览器打开</div>
<!--        <div>若浏览器打开失败，移步桌面可直接打开旺影</div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DownloadMask',
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  methods: {
    handleClose() {
      this.$emit('onClose')
    }
  }
}
</script>

<style>
.main {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);

  .promptBox {
    position: absolute;
    color: #fff;
    border-radius: 8px;
    right: 20px;
    top: 20px;
    text-align: center;
    background: url('@/assets/img/tips_bj@2x.png') no-repeat;
    background-size: 100%;
    width: 304px;
    height: 141px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .content {
      position: relative;
      bottom: 26px;
      line-height: 28px;

      & > div:first-child {
        font-size: 16px;
        font-weight: bold;
      }

      & > div:last-child {
        //margin-top: 10px;
        font-size: 14px;
      }
    }

    .arrow {
      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 60px;
        width: 20px;
        background-color: #5360ff;
        background-color: red;
        top: -25px;
        top: -25px;
        right: 20px;
        transform: rotate(45deg);
      }

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 30px solid #333;
        border-left: 30px solid transparent;
        position: absolute;
        top: -30px;
        right: 4px;
      }
    }
  }
}
</style>
