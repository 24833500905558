<template>
  <div>
    <van-popup
      v-model="visible"
      position="bottom"
      :round="true"
      :safe-area-inset-top="true"
      :close-on-click-overlay="false"
      :close-on-popstate="true"
      @closed="onClosed"
    >
      <div class="loginModal">
        <span class="close" @click="handleClickClose"></span>
        <div class="orderLoginBox">
          <div class="titleBox">绑定订单号</div>
          <form class="formBox" @submit.prevent="handleBindOrder">

            <div class="inputBox">
              <input
                      type="text"
                      class="input"
                      name="orderId"
                      v-model="orderId"
                      placeholder="请输入交易订单号（长）"
              />
            </div>
            <div class="tips">*只适合已购买用户（如何<a href="/help" class="link">获取订单号</a>）</div>
            <button class="loginBtn" type="submit">提交</button>
          </form>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Toast } from 'vant'
import {bindOrder} from '@/api'
import { mapActions } from 'vuex'

export default {
  name: 'LoginModal',
  components: {},
  data() {
    return {

      visible: false,
      onClose: () => {},
      onHandleClose: () => {},
      orderId: '', // 订单号
      onBindSuccess: () => {},
      onBindFail:() => {}
    }
  },
  methods: {
    ...mapActions('userStore', ['setUserInfo', 'updateAllCert']),

    handleClickClose() {
      this.handleClose()
    },
    handleClose() {
      this.visible = false
    },

    onClosed() {
      if (typeof this.onClose === 'function') {
        this.onClose()
      }
      this.$destroy()
    },

    /**
     * 订单号登录
     * @returns {Promise<void>}
     */
    async handleBindOrder() {
      if (!this.orderId || this.orderId?.trim().length === 0) {
        Toast('请输入订单号！')
        return
      }
      let res = await bindOrder(this.orderId)
      if (res.data.status === 0) {
       this.onBindSuccess()
        await this.updateAllCert()
      } else {
        this.onBindFail(res)
      }
      this.handleClose()
    }
  },
  created() {}
}
</script>
<style scoped lang="less">
@import url('./main.less');
</style>
