/**
 * @description index
 * @author Junker
 * @date 2021/5/20
 */

export function getBrowser(userAgent) {
  let isOpera = userAgent.indexOf('Opera') > -1 //判断是否Opera浏览器
  let isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera //判断是否IE浏览器
  let isEdge = userAgent.indexOf('Edge') > -1 || userAgent.indexOf('Edg') > -1 //判断是否IE的Edge浏览器
  let isFF = userAgent.indexOf('Firefox') > -1 //判断是否Firefox浏览器
  let isSafari = userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') == -1 //判断是否Safari浏览器
  let isChrome = userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1 //判断Chrome浏览器

  if (isIE) {
    let reIE = new RegExp('MSIE (\\d+\\.\\d+);')
    reIE.test(userAgent)
    let fIEVersion = parseFloat(RegExp['$1'])
    if (fIEVersion == 7) {
      return 'IE7'
    } else if (fIEVersion == 8) {
      return 'IE8'
    } else if (fIEVersion == 9) {
      return 'IE9'
    } else if (fIEVersion == 10) {
      return 'IE10'
    } else if (fIEVersion == 11) {
      return 'IE11'
    } else {
      return 'IE'
    }
  }
  if (isOpera) {
    return 'Opera'
  }
  if (isEdge) {
    return 'Edge'
  }
  if (isFF) {
    return 'FF'
  }
  if (isSafari) {
    return 'Safari'
  }
  if (isChrome) {
    return 'Chrome'
  }

  return 'unknown'
}

export function isMobile(userAgent) {
  return /windows phone|iphone|android/gi.test(userAgent.toLowerCase())
}

export function getMobileOS(ua) {
  const userAgent = ua.toLowerCase()
  if (/android/i.test(userAgent)) {
    return 'android'
  } else if (
    /ipad|iphone|ipod/.test(userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  ) {
    return 'ios'
  }
  return 'other'
}

export function isWeiXin(ua) {
  if (/micromessenger/.test(ua.toLowerCase())) {
    return true
  } else {
    return false
  }
}
