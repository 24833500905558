<template>
  <div id="app" @contextmenu="disableRightClick">
    <transition name="el-zoom-in-top">
      <div class="downloadBox" v-if="visible">
        <div class="left">
          <img src="https://res.yunkun.cn/img_geshicn/img/applogo.png" alt="" class="logo" />
          <div class="textBox">
            <div class="title">轻秒图片转换器APP</div>
            <div class="description">功能更多，体验更好，权益互通</div>
          </div>
        </div>
        <div class="downloadBtn" @click="handleDownloadApp">打开APP</div>
        <div class="close" @click="handleClose">
          <van-icon name="cross" />
        </div>
      </div>
    </transition>
    <router-view :class="{ have: visible }"></router-view>
    <DownloadMask :visible="show" @onClose="handleHidden" />
  </div>
</template>

<script>
import { appConfig } from '@/config'
import DownloadMask from '@/components/DownloadMask/index.vue'
import { getMobileOS, isMobile, isWeiXin } from '@/utils/getDevice'

export default {
  name: 'APP',
  components: {
    DownloadMask
  },
  data() {
    return {
      visible: false,
      timer: null,
      show: false
    }
  },

  methods: {
    disableRightClick(e) {
      e.preventDefault()
    },
    handleClose() {
      this.visible = false
    },
    handleHidden() {
      this.show = false
    },
    handleDownloadApp() {
      if (isWeiXin(window.navigator.userAgent) && isMobile(navigator.userAgent)) {
        this.show = true
      } else {
        let downloadLink = 'https://res.yunkun.cn/softdownload/qmimgapp.apk'
        if (getMobileOS(window.navigator.userAgent) === 'ios') {
          downloadLink =
            'https://apps.apple.com/cn/app/%E8%BD%BB%E7%A7%92%E5%9B%BE%E7%89%87%E8%BD%AC%E6%8D%A2%E5%99%A8/id6739018586'
        }
        const a = document.createElement('a')
        a.href = downloadLink
        a.click()
      }
    }
  },
  created() {
    let { bd_vid } = this.$route.query
    if (bd_vid) {
      window.localStorage.setItem('bd_vid', bd_vid)
    }
  },
  mounted() {
    // const isAndroid = /(Android)/i.test(navigator.userAgent)

    if (isMobile(navigator.userAgent) && appConfig.allowDownloadApp) {
      this.timer = setTimeout(() => {
        this.visible = true
        clearTimeout(this.timer)
      }, 1500)
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
}

.downloadBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 76px;
  background: url('https://res.yunkun.cn/img_geshicn/img/h5bg.png') no-repeat center/cover;
  //z-index: 9;
  padding: 0 20px;

  .left {
    display: flex;
    align-items: center;

    .logo {
      width: 40px;
      height: 40px;
      margin-right: 12px;
    }

    .title {
      margin-bottom: 2px;
      font-size: 16px;
      color: #fff;
    }

    .description {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .downloadBtn {
    border-radius: 30px;
    padding: 8px 20px;
    background: #eb7a3c;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 26px;
    height: 26px;
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.have {
  height: calc(100% - 76px) !important;
  transition: height 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
</style>
