import Vue from 'vue';
import Main from './main.vue';

let BindOrderModalConstructor = Vue.extend(Main);

const BindOrderModal = function(options, params) {
  let instance = new BindOrderModalConstructor({
    ...options,
    data: {
      onClose: params?.onClose || null,
      onHandleClose: params?.onHandleClose || null,
      onBindSuccess: params?.onBindSuccess || null,
      onBindFail: params?.onBindFail || null,
    }
  });
  instance.$mount();
  document.body.appendChild(instance.$el);
  instance.visible = true;
  return instance
}

export default BindOrderModal